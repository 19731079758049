<template>
  <b-card id="cr-parent" class="card-stock shadow-none mx-0" no-body>
    <!--      Card header-->
    <b-card-header class="px-0">
      <b-card-title>
        <span class="font-weight-bold"> Liste des stocks </span>
      </b-card-title>
      <!-- dropdown actions -->
      <b-dropdown size="sm" variant="link" toggle-class="p-0" no-caret dropleft>
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="openStockForm('add')">
          <feather-icon icon="PlusIcon" />
          <span class="align-middle ml-50">Ajouter un nouveau Stock</span>
        </b-dropdown-item>
        <b-dropdown-item @click="openStockForm('return_stock')">
          <feather-icon icon="PlusIcon"/>
          <span class="align-middle ml-50">Retourner Stock</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <!--      Card body-->
    <b-card-body class="px-0">
      <div v-if="isLoading" class="d-flex justify-content-center my-3">
        <b-spinner style="width: 4rem; height: 4rem" />
      </div>
      <div v-else>
        <b-list-group v-if="stocksList.length > 0" flush>
          <b-list-group-item
            v-for="(stock, i) in stocksList"
            :key="stock.uid"
            @click="showStock(stock)"
            class="d-flex justify-content-between align-items-center"
            button
          >
            <span>{{ i + 1 }}. {{ stock.name }}</span>
            <b-badge variant="primary" pill class="badge-round">
                            {{ stock.products_count }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
        <i v-else>Aucun stock disponible.</i>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from "bootstrap-vue";

import { createNamespacedHelpers } from "vuex";
import ToastNotificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

const { mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("stock");

export default {
  name: "StoreViewStockItemsStocksList",
  components: {
    BBadge,
    BSpinner,
    BDropdown,
    BCardBody,
    BListGroup,
    BCard,
    BCardHeader,
    BCardTitle,
    BListGroupItem,
    BDropdownItem,

    // eslint-disable-next-line vue/no-unused-components
    ToastNotificationContent,
  },
  emit: ["open-stock-form", "show-stocks"],
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLoading: {
      get: function () {
        return this.loading;
      },
      set: function (val) {
        this.$emit("update:loading", val);
      },
    },
    ...mapGetters({
      stocksList: "all",
    }),
  },
  mounted() {
    this.getAllStocks();
  },
  methods: {
    ...mapActions(["allStocks"]),
    ...mapMutations({
      setCurrentStock: "SET_RESOURCE",
    }),
    blankStockForm() {
      return {
        name: "",
        description: "",
      };
    },
    // reset stock object to default in store
    initStockForm() {
      let param = this.blankStockForm();
      this.$store.commit("stock/SET_RESOURCE", param);
    },
    openStockForm(action) {
      switch (action) {
        case "add":
          this.initStockForm();
          this.$emit("toggle-component", "Stock Form");
          break;
        case "edit":
          this.$emit("toggle-component", "Stock Form");
          break;
        default:
          break;
      }
    },
    returnStock() {

    },
    showStock(stock) {
      this.setCurrentStock(stock);
      this.$router.push({
        name: "apps-stock-provide",
        params: { storeID: this.$route.params.id, id: stock.uid },
      });
    },
    async getAllStocks() {
      try {
        this.isLoading = true;
        await this.allStocks(this.$route.params.id);
      } catch (e) {
        this.$emit(
          "stock-handled",
          "Erreur de récupération de la liste des stocks",
          "AlertTriangleIcon",
          "danger"
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
