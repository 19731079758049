<template>
  <div style="height: inherit">
    <!-- Stock Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                  icon="MenuIcon"
                  class="d-block d-lg-none"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalProducts }} résultat(s) trouvé(s)
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="toggleStockModal = true"
              >
                <feather-icon icon="ClipboardIcon" class="mr-50"/>
                <span class="align-middle">Gérer Stocks</span>
              </b-button>
              <!-- Sort Button -->
              <b-dropdown
                  class="ml-1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="sortBy.name"
                  right
                  variant="outline-primary"
              >
                <b-dropdown-item
                    v-for="sortOption in sortByOptions"
                    :key="sortOption.slug"
                    @click="sortBy = sortOption"
                >
                  {{ sortOption.name }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                  v-model="itemView"
                  class="ml-1 list item-view-radio-group"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
              >
                <b-form-radio
                    v-for="option in itemViewOptions"
                    :key="option.value"
                    :value="option.value"
                >
                  <feather-icon :icon="option.icon" size="18"/>
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Stock Header -->
    <div class="body-content-overlay"/>

    <!-- Overlay -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
                v-model="filters.q"
                placeholder="Recherche Produit"
                class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted"/>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Searchbar -->
    <section v-if="products.length > 0" :class="itemView">
      <b-card
          v-for="(product, index) in products"
          :key="index"
          class="ecommerce-card"
          no-body
      >
        <div class="item-img text-center">
          <b-link
              :to="{
              name: 'apps-stock-product-details',
              params: { id: product.details.stock, slug: product.slug },
            }"
              @click="showStockItem(product)"
          >
            <b-img
                :alt="`${index}`"
                fluid
                class="card-img-top"
                :src="product.image"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <!-- <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul> -->
              <feather-icon
                  :icon="product.is_depreciable ? 'ArrowRightIcon' : 'RotateCcwIcon'"
                  size="16"
                  class="text-warning"
              />
            </div>
            <div>
              <h6
                  class="item-price"
                  :class="[
                  isAvailableInStock(product) ? 'text-success' : 'text-danger',
                ]"
              >
                {{
                  isAvailableInStock(product) ? "Disponible" : "Indisponible"
                }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
                class="text-body"
                :to="{
                name: 'apps-stock-product-details',
                params: { slug: product.slug },
              }"
            >
              {{ product.name }}
            </b-link>
            <b-card-text class="item-company">
              Par
              <b-link class="ml-25">
                {{ product.brand ? product.brand.name : "-" }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ product.details ? product.details.description : 0 }}
          </b-card-text>
          <b-card-text class="item-description">
            {{ product.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4
                  class="item-price"
                  :class="[
                  isAvailableInStock(product) ? 'text-success' : 'text-danger',
                ]"
              >
                {{
                  isAvailableInStock(product) ? "Disponible" : "Indisponible"
                }}
              </h4>
            </div>
          </div>
          <b-button
              variant="light"
              tag="a"
              class="btn-wishlist"
              @click="toggleBookingProduct(product)"
              :disabled="!isAvailableInStock(product)"
          >
            <feather-icon
                :icon="
                product.details.is_in_wish_list ? 'PauseCircleIcon' : 'CalendarIcon'
              "
                class="mr-50"
            />
            <span>{{ product.details.is_in_wish_list ? "Attente" : "Réserver" }}</span>
          </b-button>
          <b-button
              variant="primary"
              tag="a"
              class="btn-cart"
              @click="productDispatch(product)"
              :disabled="!isAvailableInStock(product)"
          >
            <feather-icon
                :icon="product.details.is_in_cart ? 'LogInIcon' : 'LogOutIcon'"
                class="mr-50"
            />
            <span>{{ product.details.is_in_cart ? "Afficher" : "Sortir" }}</span>
          </b-button>
        </div>
      </b-card>
    </section>
    <section v-else :class="itemView">
      <i> Aucun produit trouvé pour ce stock. </i>
    </section>

    <!-- Prodcuts -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="filters.page"
              :total-rows="totalProducts"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!--    handle stock modal-->
    <b-modal
        id="stock-modal"
        v-model="toggleStockModal"
        ok-only
        ok-title="Fermer"
        centered
        title="Gérer Stocks"
        :ok-disabled="isLoading"
        :cancel-disabled="isLoading"
    >
      <component
          :is="currentComponent"
          :loading.sync="isLoading"
          @toggle-component="setStockComponent"
          @stock-handled="showToast"
      />
    </b-modal>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <store-view-stock-items-left-filter-sidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BModal,
  VBModal,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import {computed, ref, watch} from "@vue/composition-api";
import {useResponsiveAppLeftSidebarVisibility} from "@core/comp-functions/ui/app";
import StoreViewStockItemsLeftFilterSidebar from "./StoreViewStockItemsLeftFilterSidebar.vue";
import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useStoreStockItems.js";
import {useStoreViewUi} from "../useStoreView";

import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import StoreViewStockItemsStockForm from "./StoreViewStockItemsStockForm.vue";
import StoreViewStockItemsStocksList from "./StoreViewStockItemsStocksList.vue";
import store from "@/store";
import {useRouter} from "@core/utils/utils";

export default {
  name: "StoreViewStockItems",
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BModal,
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
    StoreViewStockItemsLeftFilterSidebar,
    StoreViewStockItemsStocksList,
    StoreViewStockItemsStockForm,
  },
  setup() {
    const toast = useToast();
    const {router} = useRouter();

    const {filters, filterOptions, sortBy, sortByOptions} =
        useShopFiltersSortingAndPagination();

    const {
      handleCartActionClick,
      toggleProductInWishlist,
      isAvailableInStock,
    } = useStoreViewUi();

    const {itemView, itemViewOptions, totalProducts} = useShopUi();

    const {products, fetchProducts} = useShopRemoteData();

    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility();

    const stockComponent = ref("Stocks List");
    const toggleStockModal = ref(false);
    const isLoading = ref(false);

    const productDispatch = async (product) => {
      await handleCartActionClick(product);

      const list = [...products.value];
      if (product.details.id) {
        const index = list.findIndex((p) => p.details.id === product.details.id);
        if (index > -1) {
          list[index].details.is_in_cart = true;
          products.value = list;
        }
      }
    };

    const toggleBookingProduct = async (product) => {
      await toggleProductInWishlist(product);

      const list = [...products.value];
      if (product.details.id) {
        const index = list.findIndex((p) => p?.details.id === product.details.id);
        if (index > -1) {
          list[index].details.is_in_wish_list = !list[index].details.is_in_wish_list;
          products.value = list;
        }
      }
    };

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      const queryParams = {
        q: filters.value.q,
        sortBy: sortBy.value.slug,
        page: filters.value.page,
        perPage: filters.value.perPage,
      };
      fetchProducts({
        store_id: router.currentRoute.params.id,
        query: queryParams,
      });
    };

    fetchShopProducts();

    watch(
        [filters, sortBy],
        () => {
          fetchShopProducts();
        },
        {
          deep: true,
        }
    );

    watch(
        toggleStockModal,
        (isOpened) => {
          if (!isOpened) stockComponent.value = "Stocks List";
        },
        {
          immediate: true,
        }
    );

    const currentComponent = computed(() => {
      const slug = stockComponent.value.replaceAll(" ", "-").toLowerCase();
      return "store-view-stock-items-" + slug;
    });

    const setStockComponent = (component) => {
      if (!component) stockComponent.value = "Stocks List";
      stockComponent.value = component;
    };

    const showStockItem = (product) => {
      store.commit("stock/SET_CURRENT_PRODUCT", product);
    };

    const showToast = (title, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    };

    return {
      // useShopFiltersSortingAndPagination
      isAvailableInStock,
      showStockItem,
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      isLoading,
      toggleStockModal,
      showToast,
      currentComponent,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleBookingProduct,
      productDispatch,
      setStockComponent,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
